.fadeInFromBottom {
  animation: fadeInFromBottomAnimation 0.5s ease-in-out;
}

@keyframes fadeInFromBottomAnimation {
  0% {
    opacity: 0.3;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
