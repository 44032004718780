
.inputContainer {
  margin: 8px 0px 24px;
  padding: 15px 20px;
  border: 1px solid rgba(0,0,0,0.1 );
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.inputContainerDisabled {
  border: none;
  background-color: var(--background-highlight);
}

.inputContainerDisabled .input {
  color: var(--text-light);
}

.inputContainerDisabled {
  border: none;
  background-color: var(--background-highlight);
}

.inputContainerDisabled .input {
  color: var(--text-light);
}

.input {
  border: 0;
  outline: none;
  font-size: 15px;
  flex-grow: 1;
}

.inputError {
  border: 2px solid var(--danger-soft);
}


.inputLabel {
  margin: 20px 0px 10px 0px;
  font-size: 15px;
  color: var(--text);
}

.submitButton {
  background-color: rgb(0, 0, 0);
  border: none;
  padding: 15px;
  border-radius: 999px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
  width: min(400px, 100%);
}

.or {
  width: 100%;
}
.or hr {
  flex: 1;
  background-color: var(--text-light);
  opacity: 0.2;
}

.googleContinueContainer {
  background-color: #ffffff;
  border: none;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid rgba(0,0,0,0.1 );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: 100%;
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 15px 20px;
}

.googleContinueContainer .text {
    color: var(--text-lighter);
    margin-left:10px;
}



@media only screen and (max-width: 480px) {
  .submitButton, .inputContainer {
    width: 100%;
  }
}
